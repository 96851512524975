const injectCookieScript = () => {
  function addJS(jsCode) {
    var s = document.createElement(`script`);
    s.type = `text/javascript`;
    s.innerText = jsCode;
    document.getElementsByTagName(`head`)[0].appendChild(s);
  }
  addJS(`
      link = document.getElementById("cookie-management-link");
      link.addEventListener("click", function(e) {
          UC_UI.showSecondLayer();
      });
    `);
};

exports.onRouteUpdate = () => {
  injectCookieScript();
};
