// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-remuneration-policy-js": () => import("./../../../src/pages/remuneration-policy.js" /* webpackChunkName: "component---src-pages-remuneration-policy-js" */),
  "component---src-pages-shareholder-engagement-policy-js": () => import("./../../../src/pages/shareholder-engagement-policy.js" /* webpackChunkName: "component---src-pages-shareholder-engagement-policy-js" */),
  "component---src-pages-sustainable-finance-disclosure-regulation-policy-js": () => import("./../../../src/pages/sustainable-finance-disclosure-regulation-policy.js" /* webpackChunkName: "component---src-pages-sustainable-finance-disclosure-regulation-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-whistleblowing-policy-js": () => import("./../../../src/pages/whistleblowing-policy.js" /* webpackChunkName: "component---src-pages-whistleblowing-policy-js" */)
}

